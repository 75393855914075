import React, { useState, useEffect } from "react";
import SelectVideoFile from "../../../components/input/file/SelectVideoFile";
import Axios from "../../../api/axios/Axios";
import TextArea from "../../../components/input/textarea/TextArea";
import TextField from "../../../components/input/textfield/TextField";
import "./Upload.css";
import {
  Container,
  Content,
  Main,
  Modal,
} from "../../../components/layout/Layout";
import { Button } from "../../../components/button/Button";
import { PiXCircleBold, PiCheckCircleBold } from "react-icons/pi";
import { ObjectDataValidation } from "../../../hooks/Hooks";
import gifPulse from "../../../assets/gif/Pulse.gif";
import { Confirmation } from "../../../components/prompt/Prompt";

// ⭐ S3 Upload::::::
import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { v4 as uuidv4 } from "uuid";

const UploadReelPage = () => {
  const [videoFile, setVideoFile] = useState(null);
  const [videoDuration, setVideoDuration] = useState("");
  const [assignedVideoId, setAssignedVideoId] = useState(null);
  const [caption, setCaption] = useState("");
  const [progress, setProgress] = useState(0);
  const [instagramAccount, setInstagramAccount] = useState("");
  const [email, setEmail] = useState("");

  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => generateRandomVideoId(), []);

  const generateRandomVideoId = () => {
    const newRandomId = uuidv4();
    const newVideoId = newRandomId
      .substring(0, 13)
      .replace(/[^a-zA-Z0-9]/g, "");
    return setAssignedVideoId(newVideoId);
  };

  const handleSubmit = () => {
    if (!videoFile) return;
    if (!fileSizeChecker(videoFile.size)) return;
    if (!isDurationLessThanMinute()) return;

    const data = {
      assignedVideoId: assignedVideoId,
      instagramAccount: instagramAccount,
      email: email,
      caption: caption,
      videoDuration: videoDuration,
      videoFileSize: videoFile.size,
    };

    if (!ObjectDataValidation(data) || !videoFile) {
      return alert("Please complete all required fields.");
    }

    return apiGetS3Credentials(data);
  };

  const getVideoFileObject = (file) => setVideoFile(file);
  const getVideoDuration = (time) => setVideoDuration(time);

  const fileSizeChecker = (size) => {
    const inputFileSize = size;
    const maxFileSizeInGB = 1;
    const bytes = 1024;
    const maxFileSizeAllow = Number(maxFileSizeInGB) * Number(bytes) ** 3;

    if (inputFileSize > maxFileSizeAllow) {
      const msg = "Selected file size should not exceed 7GB.";
      alert(msg);
      return false;
    } else return true;
  };

  const isDurationLessThanMinute = () => {
    const [minutes, seconds] = videoDuration.split(":").map(Number);
    const totalSeconds = minutes * 60 + seconds;
    if (totalSeconds > 60 || !videoDuration) return false;

    return true;
  };

  // --------------------------
  // 📝 Upload Selected Files:
  // --------------------------

  const apiGetS3Credentials = (data) => {
    Axios.get("/select/s3/credentials")
      .then((res) => {
        const credentials = res.data;
        setShowProgressModal(true);
        return s3UploadVideo(credentials, data);
      })
      .catch((err) => console.error(err));
  };

  const s3UploadVideo = async (s3Data, data) => {
    const {
      bucketName,
      region,
      accessKeyId,
      secretAccessKey,
      videoFileName,
      videoFolderDir,
    } = s3Data;

    const fileExtension = videoFile.type.split("/")[1];
    const outputVideoFileName = `${videoFileName}.${fileExtension}`;

    const S3 = new S3Client({
      region: region,
      credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      },
    });

    const params = {
      Bucket: bucketName,
      Key: videoFolderDir + outputVideoFileName,
      Body: videoFile,
      ContentType: videoFile.type,
    };

    const uploadToS3 = new Upload({ client: S3, params });

    uploadToS3.on("httpUploadProgress", (progressData) => {
      const percentageProgress = Math.round(
        (progressData.loaded / progressData.total) * 99
      );
      return setProgress(percentageProgress);
    });

    const response = await uploadToS3.done();

    if (response) {
      data.videoFileName = outputVideoFileName;
      return apiSubmitReelDetails(data);
    }
  };

  const apiSubmitReelDetails = (data) => {
    Axios.post("/post/reel/details", data)
      .then((res) => {
        const { success } = res.data;

        if (success) {
          setShowProgressModal(false);
          return setShowSuccessModal(true);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleInstagramAccountInput = (e) => {
    const value = e.target.value;
    return setInstagramAccount(value);
  };

  const handleEmailInput = (e) => {
    const value = e.target.value;
    return setEmail(value);
  };

  const handleCaptionInput = (e) => {
    const value = e.target.value;
    if (value.length <= 300) return setCaption(value);
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("Text");
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Container>
      <Content>
        <Main>
          <span>
            Looking for all RugbyGo games? Click here{" "}
            <a href="https://xreplay.app/rugbygo" target="_blank">
              RugbyGo Games
            </a>
          </span>
          <br />
          <h1 className="upload-reel__title">Submit Your Rugby Reel</h1>
          <p className="upload-reel__sub-title">
            Share your rugby highlights with the RugbyGo community and showcase
            your skills!
          </p>
          <br />
          <SelectVideoFile
            getVideoFileObject={getVideoFileObject}
            getVideoDuration={getVideoDuration}
          />
          <br />
          <div className="custom__spacing">
            <TextArea
              label="Add Captions *"
              placeholder="Write a caption and add hashtags.."
              value={caption}
              required
              onChange={(e) => handleCaptionInput(e)}
              onPaste={(e) => handlePaste(e)}
            />

            <TextField
              label="Enter Your Instagram Account *"
              value={instagramAccount}
              onChange={handleInstagramAccountInput}
              placeholder="e.g. @myinsta"
              required
            />

            <TextField
              type={"email"}
              label="Enter Your Email *"
              value={email}
              onChange={handleEmailInput}
              placeholder="e.g. email@domain.com"
              required
            />
          </div>
          <br />
          <div
            style={{
              display: "flex",
              gap: 16,
              width: "100%",
            }}
          >
            <Button
              variant={"outlined"}
              onClick={() => refreshPage()}
              label="Reset"
            />
            <Button onClick={() => handleSubmit()} label="Submit" />
          </div>
          <br /> <br />
          <h2>Checklist</h2>
          <br />
          <ul className="upload_checklist__ul">
            <li>
              {!videoFile ? (
                <PiXCircleBold className="checklist__icon red" />
              ) : (
                <PiCheckCircleBold className="checklist__icon green" />
              )}
              Have you selected a video?
            </li>

            <li>
              {!isDurationLessThanMinute() ? (
                <PiXCircleBold className="checklist__icon red" />
              ) : (
                <PiCheckCircleBold className="checklist__icon green" />
              )}
              Is the video less than a minute long?
            </li>

            <li>
              {!caption ? (
                <PiXCircleBold className="checklist__icon red" />
              ) : (
                <PiCheckCircleBold className="checklist__icon green" />
              )}
              Have you added a caption to your video?
            </li>

            <li>
              {!instagramAccount ? (
                <PiXCircleBold className="checklist__icon red" />
              ) : (
                <PiCheckCircleBold className="checklist__icon green" />
              )}
              Have you linked your Instagram account?
            </li>

            <li>
              {!email ? (
                <PiXCircleBold className="checklist__icon red" />
              ) : (
                <PiCheckCircleBold className="checklist__icon green" />
              )}
              Have you entered your email?
            </li>
          </ul>
          <br /> <br />
        </Main>
      </Content>

      {showProgressModal && (
        <Modal closeModal={setShowProgressModal}>
          <div className="upload-progress-bar__wrapper">
            <img src={gifPulse} alt="pulse" />
            <span className="upload-progress-bar__counter">
              Submitting... {progress}%
            </span>
          </div>
        </Modal>
      )}

      {showSuccessModal && (
        <Modal closeModal={setShowSuccessModal}>
          <Confirmation
            title={"👍 Awesome!"}
            message={"We'll check and post your reel to RugbyGo"}
            showButton={true}
            label={"Return"}
            onClick={() => {
              refreshPage();
              return setShowSuccessModal(false);
            }}
          />
        </Modal>
      )}
    </Container>
  );
};

export default UploadReelPage;
